<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <template v-for="(p, i) in menuList">
        <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

        <app-bar-item v-else :key="`item-${i}`">
          <v-list-item-content @click="listener(p.title)">
            <v-list-item-title v-text="p.title" />
          </v-list-item-content>
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "DefaultAccount",

  data: () => ({
    profile: [
      { title: "Settings" },
      { divider: true },
      { title: "Admin" },
      { divider: true },
      { title: "Log out" },
    ],
  }),
  computed: {
    menuList() {
      var list = []; 
      list.push({ title: "Admin" });
      list.push({ divider: true });
      
      list.push({ title: "Settings" });
      list.push({ divider: true });
      list.push({ title: "Log out" });
      return list;
    },
  },
  methods: {
    listener(title) {
      if (title === "Log out") {
        this.$store
          .dispatch("authUser/logout")
          .then(() => this.$router.push("/login"));
      } else if (title === "Settings") {
        this.$router.push("/settings");
      } else if (title === "Admin") {
        this.$router.push("/admin/admin-bills");
      }
    },
  },
};
</script>
